import { flosIconSVG } from '../utils/svg-tag-generator';

/**
 * These shapes replaces the ones in TopdanmarkShapes, because we have the need to style them a little different that the ones generated from the figma exports
 * If adding new shapes, remember to add the shape key to the shapesToSkip array in /scripts/generate-shapes.mjs
 */
const AnimatedShapes = {
  'notification': flosIconSVG(
    '' +
      '<circle stroke="currentColor" opacity="0.2" cx="32" cy="32" r="16">' +
      '<animate attributeName="r" from="8" to="24" dur="1.5s" begin="0s" repeatCount="indefinite"/>' +
      '<animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>' +
      '</circle>' +
      '<circle stroke="currentColor" stroke-width="0" opacity="0.2" cx="32" cy="32" r="16">' +
      '<animate attributeName="r" from="8" to="20" dur="1.5s" begin="0s" repeatCount="indefinite"/>' +
      '<animate attributeName="opacity" from="0.5" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>' +
      '</circle>' +
      '<circle stroke="currentColor" stroke-width="0" cx="32" cy="32" r="8"/>',
    '0 0 64 64'
  ),
  'circle-check-animated': flosIconSVG(
    '<circle stroke="currentColor" cx="32" cy="32" r="23.5"/>' +
      '<path stroke="currentColor" class="checkmark" d="M21.1992 31.9998L28.3992 39.1998L42.7992 24.7998"/>',
    '0 0 64 64'
  ),
};

export { AnimatedShapes };
