import { flosIconSVG } from '../utils/svg-tag-generator';

const OldShapes: { [key: string]: string } = {
  'basket': flosIconSVG(
    `<g transform="translate(0 .5)">
        <path stroke="currentColor" d="M0 0h3.018a6.268 6.268 0 015.645 3.546l9.4 19.5h29.8M49.614 15.589H14.468M52.247 7.795H10.609"/>
        <circle stroke="currentColor" cx="3.529" cy="3.529" r="3.529" transform="translate(14.468 29.034)"/>
        <circle stroke="currentColor" cx="3.529" cy="3.529" r="3.529" transform="translate(41.658 29.034)"/>
    </g>`,
    '0 0 52.247 37.093'
  ),
  'check2': flosIconSVG(`<path stroke="currentColor" d="M12,.6,5,9.94.65,6.2"/>`, '0 0 12.75 11.4'),
  'child': flosIconSVG(
    `<g transform="translate(.5 .5)">
        <circle stroke="currentColor" cx="5.25" cy="5.25" r="5.25" transform="translate(2.48)"/>
        <path stroke="currentColor" d="M15.46 33.878V21.352a6.613 6.613 0 00-6.613-6.613H6.614A6.613 6.613 0 000 21.352v12.619M4.899 33.397v12.637M10.562 33.397v12.637"/>
    </g>`,
    '0 0 16.46 46.534'
  ),
  'money-notes': flosIconSVG(
    `<g transform="translate(0 .5)">
        <path stroke="currentColor" d="M56.276 21.658V9.591A9.591 9.591 0 0146.685 0H10.244A9.591 9.591 0 01.653 9.591v12.067a9.59 9.59 0 019.591 9.591h36.442a9.59 9.59 0 019.59-9.591zm-27.811 3.557a9.591 9.591 0 119.59-9.591 9.591 9.591 0 01-9.59 9.591z"/>
        <circle stroke="currentColor" cx="9.591" cy="9.591" r="9.591" transform="translate(18.874 6.033)"/>
        <path stroke="currentColor" d="M56.277 21.658a9.59 9.59 0 00-9.591 9.591h9.591zM56.277 9.591V0h-9.591a9.591 9.591 0 009.591 9.591zM10.244 0H.653v9.591A9.591 9.591 0 0010.244 0zM.653 21.658v9.591h9.591a9.59 9.59 0 00-9.591-9.591zM0 36.504h56.92"/>
    </g>`,
    '0 0 56.92 37.504'
  ),
  'pen': flosIconSVG(
    `<g stroke-linejoin="bevel">
    <path stroke="currentColor" d="M8.085 44.41l-3.793 4.668L.499 44.41V.5h7.586z"/>
    <path stroke="currentColor" d="M4.293 54.33v-5.252"/>
    <path stroke="currentColor" d="M.5 7.5h13.566v16.049"/>
  </g>`,
    '0 0 14.567 54.33'
  ),
};

export { OldShapes };
